<template>
    <h1 class="page-header label-indigo" >
    <button class="btn btn-default ml-5" @click="$router.go(-1)" style="margin-right:13px; margin-bottom:7px; width:70px"><i class="fas fa-angle-left"></i></button>Manage  Industries
    </h1>
  
    
    
    <div class="row">
    
    <div class="card">
    <ul class="nav nav-tabs nav-tabs-v2 px-4">
    <li class="nav-item me-3"><a href="#allTab" class="nav-link active px-2" data-bs-toggle="tab" @click.prevent="changefilterlist('')">All</a></li>
    <li class="nav-item me-3"><a href="#publishedTab" class="nav-link px-2" @click.prevent="changefilterlist('active')">Active </a></li>
    <li class="nav-item me-3"><a href="#expiredTab" class="nav-link px-2" @click.prevent="changefilterlist('suspended')">Suspended</a></li>
    <li class="nav-item me-3"> <button class="nav-link px-2" @click.prevent="show_add_data_form">Create New</button></li>
    </ul>
    
    <div id="newindustrydata" class="hideall">
        <form class="pt-3" @submit.prevent="save_formdata">
            <div class="card   mb-4" id="newbannerdata">
            <div class="card-header"><h5 class="card-title">Add New Industry </h5></div>
            <div class="card-body">
                <div class="form-group row mb-3">
                
                <div class="col-sm-12">
                    <label class="form-label" for="exampleFormControlInput1">Enter Industry Title</label>
                    <input type="text" v-model="forminput.title" class="form-control" required placeholder="Enter Title for the Industry">
                    <i class="text-warning" v-if="formerror.title">{{ formerror.title }}</i>
                </div>

                <div class="col-sm-12 mt-4">
                    <button type="submit" style="width: 100%;" class="btn btn-indigo btn-block" >Submit </button>
                </div>
                </div>


            </div>
        </div>
        </form>
    </div>

    <div id="updateindustrydata" class="hideall">
        <form class="pt-3" @submit.prevent="updateindustrydetails" v-if="industry">
            <div class="card   mb-4" id="newbannerdata">
            <div class="card-header"><h5 class="card-title">Update Industry </h5></div>
            <div class="card-body">
                <div class="form-group row mb-3">
                
                <div class="col-sm-12">
                    <label class="form-label" for="exampleFormControlInput1">Enter Industry Title</label>
                    <input type="text" v-model="industry.title" class="form-control" required placeholder="Enter Title for the Industry">
                    <i class="text-warning" v-if="formerror.title">{{ formerror.title }}</i>
                </div>

                <div class="col-sm-12 mt-4">
                    <button type="submit" style="width: 100%;" class="btn btn-indigo btn-block" >Save Update </button>
                </div>
                </div>


            </div>
        </div>
        </form>
    </div>
    
    <div class="tab-content p-4" id="industrydata">
    <div class="tab-pane fade show active" id="allTab">
    
        <div class="row">
    <div class="col-md-1 col-sm-2">
        <select v-model="per_page" class="form-control" @change="getpagedatalist(current_page)">
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
        </select>

    </div>
    <div class="col-md-11 col-sm-10">
        <div class="input-group mb-4">
        <div class="flex-fill position-relative">
        <input type="search" @input="comp_search_page_data(current_page)"  v-model="search_filter"  class="form-control " placeholder="Search Industries" />
        </div>
        </div>
    </div>

</div>
    
    
    <div class="table-responsive">
    <table class="table table-hover text-nowrap">
    <thead>
    <tr>
    <th class="border-top-0 pt-0 pb-2">#</th>
    <th class="border-top-0 pt-0 pb-2">Industries</th>
    <th class="border-top-0 pt-0 pb-2">Professions</th>
    <th class="border-top-0 pt-0 pb-2">Collections</th>
    <th class="border-top-0 pt-0 pb-2" style="text-align: center">Status</th>
    <th class="border-top-0 pt-0 pb-2" style="text-align: center">Action</th>
    </tr>
    </thead>
    <tbody v-if="industries">

    <tr v-for="(item, index) in filteredlist" :key="item.id" style="cursor:pointer !important" >
    
        <td class="align-middle">{{ ((current_page - 1) * per_page) + index + 1}}</td>
        <td class="align-middle" style="width:50%">{{ item.title }}</td>
        <td class="align-middle" style="width:10%; text-align: center;">{{ item.professions_count }}</td>
        <td class="align-middle" style="width:10%; text-align: center;">{{ item.collection_count_count }}</td>
        <td class="align-middle" style="width:15%; text-align: center">
            <button v-if="item.status == 1" @click.prevent="changeitemstatusbtn(item.id)" class="badge btn btn-teal  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Suspend</button>
            <button v-if="item.status == 0" @click.prevent="changeitemstatusbtn(item.id)" class="badge btn btn-indigo  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Unsuspend</button>
        </td>
    
    
        <td class="align-middle" style="text-align: center">
      
        <button @click.prevent="show_update_data_form(item)"  class="badge btn btn-warning  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" style="margin-right:6px">Update</button>
    
        <button @click.prevent="deletesingleindustry(item.id)"  class="badge btn btn-danger  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" style="margin-right:6px">Delete</button>
       </td>
    
    </tr>
    
    
    
    
    </tbody>
    </table>
    </div>
    
    

    <div class="d-md-flex align-items-center" v-if="industries">
    <div class="me-md-auto text-md-left text-center mb-2 mb-md-0">
    Showing {{ industries.from }} to {{ industries.to }} of {{ industries.total }} entries
    </div>

    <nav >
    <ul class="pagination mb-0 justify-content-center">
        <li class="page-item " :class="{'disabled' : current_page < 2}">
        <button class="page-link" href="#" tabindex="-1"  @click.prevent="getpagedatalist(current_page - 1)" >Previous</button>
        </li>
        
        <li class="page-item active">
        <a class="page-link" href="#">Current Page : {{ current_page }}</a>
        </li>
    
        <li class="page-item" :class="{'disabled' : current_page == industries.last_page}">
        <button class="page-link"  @click.prevent="getpagedatalist(current_page + 1)"  href="#">Next</button>
        </li>
    </ul>
    </nav>

    </div>
    
    
    
    </div>
    </div>
    </div>
    
    
    
    </div>
    
    </template>
    <script>
    import {mapGetters, mapActions } from 'vuex'
    export default {
    
        data(){
            return{
                forminput:{
                    title: '',
                    country_id:''
                },
                formerror:{
                    title: '',
                    country_id:''
                },
    
                filtertype:'',
                search_filter:'',
                current_page:1,
                sort_field:'',
                sort_order:'desc',
                per_page:50,
                search:'',
                status:'',
                industry:{}
            }
        },

         computed:{
            ...mapGetters(['msg', 'error','pageloader','loading', 'industries']),


            filteredlist(){
                let filteredList = [];
                if(this.filtertype == 'active'){
                    filteredList =  this.industries.data.filter(co => co.status == 1)
                }else if(this.filtertype == 'suspended'){
                    filteredList = this.industries.data.filter(co => co.status == 0)
                }else {
                    filteredList = this.industries.data
                }

                filteredList.sort((a, b) => {
                    // Assuming the title is a string field in your profession objects
                    const titleA = a?.title.toUpperCase() ?? 'ZZZZZZ';
                    const titleB = b?.title.toUpperCase() ?? 'ZZZZZZ';

                    if (titleA < titleB) {
                        return -1;
                    }
                    if (titleA > titleB) {
                        return 1;
                    }
                    return 0;
                });

                return filteredList;
            },
            },
    
    
        methods:{
            ...mapActions(['saveindustry','getindustrylist', 'getindustry','updateindustry','getserachedindustries','changeindustriestatus','deleteindustry']),
    
            save_formdata(){
                 if(this.forminput.title == ''){
                    this.formerror.title = 'Industry Name is Required';
                    setTimeout(() => this.formerror.title = '', 3000)
                }{
                let data = {
                    title:this.forminput.title,
                }
                this.saveindustry(data).then(()=>{
                    this.getpagedatalist(this.current_page); 
                    this.forminput.title = '';
                })
                }
    
            },
            
            updateindustrydetails(){
                 if(this.industry.title == ''){
                    this.formerror.title = 'industry Name is Required';
                    setTimeout(() => this.formerror.title = '', 3000)
                }else{
                let data = {
                    title:this.industry.title,
                }
                let id = this.industry.id;
                this.updateindustry({id, data}).then(()=>{
                    this.cancel_data_form()
                    this.getpagedatalist(this.current_page);         
                })
                }
            },

            changefilterlist(value){
            this.filtertype = value;
            this.cancel_data_form()
        },
    
            getsingleindustry(id){
                this.getindustry(id)
            },
    
            deletesingleindustry(id){
                this.deleteindustry(id).then(()=>{
                    this.getpagelist();  
                })
            },
    
            changeitemstatusbtn(id){
                this.changeindustriestatus(id).then(()=>{
                    this.getpagelist();  
                })
            },
    
            updateindustrydetails(){
                 if(this.industry.title == ''){
                    this.formerror.title = 'industry Name is Required';
                    setTimeout(() => this.formerror.title = '', 3000)
                }else{
                let data = {
                    title:this.industry.title,
                }
                let id = this.industry.id;
                this.updateindustry({id, data}).then(()=>{
                    this.cancel_data_form()
                    this.getpagedatalist(this.current_page);  
                        
                })
                }
            },
    
            comp_search_page_data(page){
                if(this.search_filter){
                    if(this.search_filter.length > 2){
                        this.search = this.search_filter
                        this.current_page = 1;
                        this.getpagedatalist(this.current_page)
                    }else{
                        this.current_page = 1;
                        this.getpagedatalist(this.current_page)
                    }
                }
            },

        
        
            getpagedatalist(page){
                this.current_page = page;
                let data = {
                    sort_field:this.sort_field,
                    sort_order:this.sort_order,
                    per_page:this.per_page,
                    search:this.search,
                }
                this.getindustrylist({data, page});
            },

          

            show_add_data_form(){
                if($('#newindustrydata').hasClass('hideall')){
                    $('#newindustrydata').removeClass('hideall')
                    $('#industrydata').addClass('hideall')
                    $('#updateindustrydata').addClass('hideall')
                }else{
                    $('#newindustrydata').addClass('hideall')
                    $('#industrydata').removeClass('hideall')
                    $('#updateindustrydata').addClass('hideall')
                }
            },

            show_update_data_form(industry){
                if(industry){
                    this.industry = Object.assign(industry)
                    if($('#updateindustrydata').hasClass('hideall')){
                        $('#updateindustrydata').removeClass('hideall')
                        $('#newindustrydata').addClass('hideall')
                        $('#industrydata').addClass('hideall')
                        
                    }else{
                        $('#updateindustrydata').addClass('hideall')
                        $('#newindustrydata').removeClass('hideall')
                        $('#industrydata').removeClass('hideall')
                    }
                }
                
            },

            cancel_data_form(){
                $('#newindustrydata').addClass('hideall')
                $('#industrydata').removeClass('hideall')
                $('#newindustrydata').addClass('hideall')
                $('#updateindustrydata').addClass('hideall')

            },
    
    
    
        },
    
        
    
        created(){
            let page = this.current_page
            this.getpagedatalist(page);
            
        }
    
    }
    </script>